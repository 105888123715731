<template>
  <section v-if="design && vendor">
    <div class="Eventos-slider row">
      <div class="container" style="font-size: 3.5rem" :style="{ color: designEvent.colorTitleEvent }">
        <h2 class="Eventos-sliderTitle">{{ name }}</h2>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ventos-header',
  props: ['name']
};
</script>
<style lang="scss" scoped>
.Eventos {
  &-headerLogo {
    max-height: 100px;
  }
  &-slider {
    background-size: cover;
    background-position: center center;
    height: 450px;
    display: flex;
    align-items: center;
  }
  &-sliderTitle {
    width: 600px;
    display: block;
    color: #fff;
  }
  @media(max-width: 414px) {
    &-sliderTitle {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      padding: 20px;
      text-shadow: 1px 1px 1px #000;
    }
  }
}
</style>
