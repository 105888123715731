<template>
  <section class="Selection" id="selectionContent" v-if="designEvent && event">
    <div class="container">
      <div class="Selection-row columns" >
        <div class="column">
          <div class="Selection-peopleButtons" :class="[people > 0 ? 'button-people' : 'background-white']" :style="{ backgroundColor: designEvent.colorTitleEvent }">
            <div class="Selection-peopleButton" :style="{ color: designEvent.colorHeadEvent }" :class="[people <= info.minimumChargedPeople ? 'disabled-div' : '']" @click.capture="people--">
              <i class="fa fa-minus "></i>
            </div>
            <div class="Selection-peopleText" :style="{ color: designEvent.colorHeadEvent }">{{ people }}</div>
            <div class="Selection-peopleButton" :style="{ color: designEvent.colorHeadEvent }" :class="[people == info.maxPeople ? 'disabled-div' : '']" @click.capture="people++">
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="column" :style="{ color:  designEvent.colorTitleEvent}">
          <span v-if="Array.isArray(event.date)"> {{ event.date }} </span>
          <div class="Selection-dateText" v-else :style="{ backgroundColor: designEvent.colorTitleEvent, color:  designEvent.colorHeadEvent }">
            {{ new Date(parseInt(event.date)) | moment( 'timezone', vendorTimezone, 'dddd, DD MMM Y') }}
          </div>
        </div>
        <div class="column">
          <div class="btn-group w-100" role="group">

            <b-dropdown aria-role="list" class="role-dow">
              <button class="Selection-ButtonDate btn btn-secondary dropdown-toggle w-100" slot="trigger" :disabled="people <= 0">
                  <span>{{ (time === null) ? 'Seleccione un horario' : new Date(parseInt(time)) | moment( 'timezone', vendorTimezone, 'hh:mm a')  }}</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>

              </button>
              <b-dropdown-item aria-role="listitem" v-for="(item, key) in schedule" :key="'secEvent' + key" :value="item.inicio" @click="setTime(item)"
                :style="[(time == item.inicio) ? { backgroundColor: designEvent.colorHeadEvent, color: designEvent.colorTitleEvent } : '']"
                :class="[ !item.status ? 'disabled' : '']">
                {{ new Date(parseInt(item.inicio)) | moment( 'timezone', vendorTimezone, 'hh:mm a') }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="buttons-box">
          <button class="btn-reservation btn w-100 fw-700" :disabled="time === null || people < info.minimumChargedPeople" @click="showLogin"
            :style="{ color:  designEvent.colorHeadEvent, backgroundColor: designEvent.colorTitleEvent }" v-if="!send" >
            Reservar
          </button>
          <span class="btn-reservation btn w-100 fw-700" :style="{ color:  designEvent.colorHeadEvent, backgroundColor: designEvent.colorTitleEvent }" v-if="send">
            <ball-spin-fade-loader :color="designEvent.colorHeadEvent" size="3px"></ball-spin-fade-loader>
            Procesando
          </span>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import authTypes from '@/store/types/auth';
import whiteLabelTypes from '@/store/types/whiteLabel';
import eventTypes from '@/store/types/event';
import reservationTypes from '@/store/types/reservation';
export default {
  name: 'selection',
  props: ['info', 'letModl'],
  data: () => ({
    people: 0,
    time: null,
    schedule: [],
    modalLogin: false,
    userPeople: null,
    reservation: null,
    eventDateId: null,
    send: false
  }),
  watch: {
    people () {
      this.times();
    },
    letModl: function () {
      this.validateUser();
    }
  },
  computed: {
    ...mapGetters({
      event: [eventTypes.getters.event]
    })
  },
  methods: {
    preReserve () {
      let data = this.reservationsUser;
      let action = data.isEvent
        ? 'event:preReservationEvent'
        : 'reservation:preReservation';
      this.$store.dispatch({ type: action, data }).then(response => {
        if (parseInt(response.code) === 705 || parseInt(response.code) === 410) {
          this.$store.commit(
            reservationTypes.mutations.setSendReservation,
            false
          );
          this.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>Disculpe, este horario no se encuentra disponible para reservar.",
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
          this.send = false;
        } else {
          this.send = false;
          if (Array.isArray(response.vendorAvailability)) {
            window.localStorage.setItem(
              '_reservation_time',
              btoa(response.vendorAvailability[0].created_at)
            );
          } else {
            window.localStorage.setItem(
              '_reservation_time',
              btoa(response.vendorAvailability.created_at)
            );
          }
          let dataR = JSON.parse(atob(window.localStorage.getItem('_reservation')));
          if (dataR.futureReservationsId && dataR.vendorAvailability) {
            window.location.href = '/checkout';
          } else {
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>Error al crear reserva intente de nuevo por favor.",
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
          }
        }
      });
    },
    book () {
      // Formato de fecha en año-mes-dia
      let date = this.$moment(new Date(parseInt(this.time))).format('YYYY-MM-DD');
      // Se obtienen los datos del navegador y dispositivo
      let dataBrowser = this.userAgentBrowser().parsedResult;
      // Valido si el evento es pago o libre
      let isFree = false;
      if (
        this.info.purchaseCommission === 0 ||
        this.info.purchaseCommission === null
      ) {
        isFree = true;
      }
      let isEvent = true;
      if (this.info.type === 'experience') {
        isEvent = false;
      }
      this.reservation = this.userPeople;
      this.reservation.userId = this.userPeople.userId;
      this.reservation.vendorId = this.$store.getters['whiteLabel:vendorId'];
      this.reservation.people = this.people;
      this.reservation.date = date;
      this.reservation.dateTime = parseInt(this.time);
      this.reservation.isFree = isFree;
      this.reservation.balance = isFree
        ? null
        : this.people * this.info.purchaseCommission;
      this.reservation.referrer = 'event';
      this.reservation.comments = '';
      this.reservation.isEvent = isEvent;
      this.reservation.zone = false;
      this.reservation.dataBrowser = dataBrowser;
      this.reservation.eventId = this.info.eventId;
      this.reservation.eventDateId = this.eventDateId;
      this.reservation.reservationEvent = this.info.isCover;
      // almaceno la data de la reserva en el localStorage
      window.localStorage.setItem(
        '_reservation',
        btoa(JSON.stringify(this.reservation))
      );
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
      this.preReserve();
    },
    showLogin () {
      this.send = true;
      if (this.user.length <= 0) {
        this.$store.commit(
          whiteLabelTypes.mutations.setShowModalLogin,
          !this.showModalLogin
        );
        this.$store.commit(reservationTypes.mutations.setSendReservation, false);
      } else {
        this.userPeople = {};
        this.userPeople.displayName = this.user.displayName;
        this.userPeople.email = this.user.email;
        this.userPeople.phone = this.user.phone;
        // eslint-disable-next-line
        this.userPeople.indicativo = this.userPeople.indicativo;
        this.userPeople.userId = this.user.id || this.user.userId;
        window.localStorage.setItem(
          '_user',
          btoa(JSON.stringify(this.userPeople))
        );
        this.$store.commit(authTypes.mutations.setUser);
        this.book();
      }
    },
    setTime (item) {
      if (item.status) {
        this.time = item.inicio;
        this.eventDateId = item.eventDateId;
      }
    },
    times () {
      this.$store
        .dispatch({
          type: 'event:getTimesEvent',
          data: { eventId: this.info.eventId, people: this.people }
        })
        .then(response => {
          this.schedule = response.data;
        });
    },
    validateUser () {
      this.modalLogin = false;
      this.userPeople = this.people;
      this.showLogin();
    }
  },
  mounted () {
    this.people = this.info.minimumChargedPeople || 1;
    this.$store.commit(authTypes.mutations.setUser);
    this.$store.commit(authTypes.mutations.setUserId);
    // localStorage.removeItem("_user");
    // localStorage.removeItem("_userId");
    localStorage.removeItem('_reservation');
  }
};
</script>
<style lang="scss" scoped>
.Selection {
  &-row {
    padding: 20px;
    border-radius: 5px 5px 0 0;
    margin-top: -5rem;
    background-color: var(--bgColorStickPay);
    color: var(--colorStickPay);
    margin-top: -4rem !important;
    padding: 20px 30px;
    margin-left: 0;
    margin-right: 0;

    @media (min-width: 768px) {
      margin-top: -4rem;
      padding: 30px;
    }
    @media (max-width: 767px) {
      .col-12 {
        margin: 5px 0px;
      }
    }
  }
  &-people {
    .row {
      padding: 5px;
      border-radius: 3rem;
    }
    &Button {
      cursor: pointer;
    }
    &Text {
      font-size: 18px;
      font-weight: 600;
    }
  }
  &-peopleButtons {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6px;
    border-radius: 20px;
    color: var(--bgColorStickPay);
  }

  &-dateText {
    border-radius: 20px !important;
    padding: 6px 25px !important;
    text-align: center;
  }

  &-Button {
    &Date {
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      height: 100%;
      padding: 7px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      outline: none;
      .fa {
        color: #000;
        margin: 0 5px;
      }
    }
    &Book {
      &:focus {
        outline: none !important;
        box-shadow: unset;
      }
      &:hover {
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19) !important;
        transform: scale(1.05);
      }
    }
  }
  &-date {
    cursor: pointer;
    &Text {
      font-size: 18px;
      font-weight: 600;
      padding: 6px 15px;
      border-radius: 3px;
    }
  }
}

.buttons-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-reservation {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    padding: 10px 20px;
    cursor: pointer;
    background: var(--bgColorStickPay);
    color: var(--colorStickPay);
  }
}

.disabled {
  opacity: 0.6;
}
.disabled-div {
  pointer-events: none;
  opacity: 0.6;
}
.dropdown-menu {
  width: 100%;
  text-align: center;
}
.dropdown-item:active {
  background-color: transparent !important;
}
</style>
