<template>
  <section class="infoEvent">
    <div class="infoEvent-row row"  :style="{ 'border-color': designEvent.colorHeadEvent }">
      <div class="col-12">
        <h5 class="fw-700 infoEvent-text">
          <span>Información del Evento </span>
          <div class="text-right" v-if="info.purchaseCommission && info.purchaseCommission > 0">
            {{ info.purchaseCommission | currency('$')}}<br>
            <small class="fw-600">Anticipo Por persona </small>
          </div>
        </h5>
        <h6 class="fw-600 text-right">{{ info.chef }}</h6>
      </div>
      <div class="infoEvent-description col-12 f14 fw-500 text-justify" v-html="info.description"></div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'infoEvent',
  props: ['designparams', 'info']
};
</script>
<style lang="scss" scoped>
.infoEvent {
  background: #fff;
  &-row {
    border: 1px;
    border-style: solid;
    padding: 20px;
    border-radius: 0 0 3px 3px;
  }
  &-text {
    @media (max-width: 768px) {
      text-align: center;
    }
    span {
      @media (max-width: 768px) {
        margin-bottom: 20px;
        display: block;
      }
    }
  }
  &-description {
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
}
</style>
