<template>
  <footer class="footer" v-if="vendor && design && params">
    <div class="container overflow-h" >
      <div class="build-bottom columns">
        <div class="column">
          <!--button class="btn btn-primary btn-siguiente disabled" id="btn-5" data-toggle="modal" data-target="#exampleModalCenter" type="button">LLENA TODOS LOS DATOS</button-->
          <p class="info-footer">
            <i class="fa fa-phone"></i> {{ vendor.phone }} |
            <i class="fa fa-envelope-square"></i> {{ vendor.email }} <br>
            <i class="fa fa-map-marker"></i>
            {{ vendor.address1 }} <span v-if="vendor.address2"> {{ vendor.address2 }}</span><br>
            {{ vendor.city.name+', '+vendor.country}}
          </p>
        </div>
        <div class="column footer-logo" >
          BUILT BY | <i class="icon-mks icon-logo-precompro"></i> {{versionApp}}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['designparams'],
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser
    })
  }
};
</script>
<style scoped>
.footer {
  padding-top: 20px !important;
}
</style>
